import Vue from 'vue';

import './styles/quasar.sass';
import '@quasar/extras/mdi-v5/mdi-v5.css';
import '@quasar/extras/material-icons/material-icons.css';
import {
  Quasar,
  QLayout,
  QHeader,
  QDrawer,
  QPageContainer,
  QPage,
  QToolbar,
  QToolbarTitle,
  QBtn,
  QIcon,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  // My extensions
  QBar,
  QIntersection,
  QCard,
  QCardSection,
  QCardActions,
  QChip,
  QInput,
  QFooter,
  QTooltip,
  QBtnDropdown,
  QBtnGroup,
  QDialog,
  QSlideTransition,
  QSeparator,
  QSelect,
  QInnerLoading,
  QSpinnerPie,
  QPageSticky,
  QCircularProgress,
  QImg,
  QAvatar,
  QResizeObserver,
  QSplitter,
  QSpace,
  QSlider,
  QToggle,
  QFab,
  QFabAction,
  QForm,
  QTable,
  QTh,
  QTr,
  QTd,
  QCheckbox,
  QFile,
  QScrollArea,
  // My plugins
  Dialog,
  Notify,
  // My directive
  ClosePopup,
} from 'quasar';

Vue.use(Quasar, {
  config: {
    brand: {
      primary: '#A70E16',
    },
  },
  components: {
    QLayout,
    QHeader,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    // my addons
    QBar,
    QIntersection,
    QCard,
    QCardSection,
    QCardActions,
    QChip,
    QInput,
    QFooter,
    QTooltip,
    QBtnDropdown,
    QBtnGroup,
    QDialog,
    QSlideTransition,
    QSeparator,
    QSelect,
    QInnerLoading,
    QSpinnerPie,
    QPageSticky,
    QCircularProgress,
    QImg,
    QAvatar,
    QResizeObserver,
    QSplitter,
    QSpace,
    QSlider,
    QToggle,
    QFab,
    QFabAction,
    QForm,
    QTable,
    QTh,
    QTr,
    QTd,
    QCheckbox,
    QFile,
    QScrollArea,
  },
  directives: { ClosePopup },
  plugins: { Dialog, Notify },
});
