var render, staticRenderFns
import script from "./TOSPopup.vue?vue&type=script&lang=js&"
export * from "./TOSPopup.vue?vue&type=script&lang=js&"
import style0 from "./TOSPopup.vue?vue&type=style&index=0&id=9afed70a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9afed70a",
  null
  
)

export default component.exports